import html2canvas from 'html2canvas'

function canvas () {
  const options = { type: 'dataURL' }

  return async element => {
    const canvas = await html2canvas(element, options)
    return canvas.toDataURL()
  }
}

export const getHTML2canvasDataURL = canvas()
